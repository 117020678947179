export const sleep = (ms) => {
    new Promise(resolve => setTimeout(resolve, ms))
}

export const FARMS = [{
    pid: 0,
    lpAddress: "0xF5349B154829a8Eceb99FDdb90E81E53dB61a8dD",
    lpSymbol: "TMAT-XDC LP",
    tokenSymbol: "TMAT",
    tokenAddress: "0x4fb5519aD7bC90B17de1343894FeFC9e320bB745",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: false
},
{
    pid: 1,
    lpAddress: "0x0B28Fd83f6e050dB22eA646BC9609f5d4D590E19",
    lpSymbol: "XSP-XDC LP",
    tokenSymbol: "XSP",
    tokenAddress: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: false
},
{
    pid: 2,
    lpAddress: "0xF5349B154829a8Eceb99FDdb90E81E53dB61a8dD", // native - usd
    lpSymbol: "TMAT",
    tokenSymbol: "TMAT",
    tokenAddress: "0x4fb5519aD7bC90B17de1343894FeFC9e320bB745",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: true
}
    // ,
    // {
    //     pid: 3,
    //     lpAddress: "",
    //     lpSymbol: "WXDC",
    //     tokenSymbol: "WXDC",
    //     tokenAddress: "",
    //     quoteTokenSymbol: "",
    //     quoteTokenAddress: "",
    //     isTokenOnly: true
    // }
]

export const stakeTokens = [
    // Main-net




    {
        token: 'PAYU',
        address: "0x9AeB2E6DD8d55E14292ACFCFC4077e33106e4144",
        decimal: 18
    },
    {
        token: "WBNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        decimal: 18

    },

    {
        token: "USDT",
        address: "0x55d398326f99059fF775485246999027B3197955",
        decimal: 18

    },

    {
        token: "CAKE",
        address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        decimal: 18

    },

    {
        token: "DOGE",
        address: "0xba2ae424d960c26247dd6c32edc70b295c744c43",
        decimal: 8

    },


]

export const rewardTokenDetails = [



    {
        token: 'BITCOIN',
        address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        decimal: 18
    },

    {
        token: "WBNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    },

    {
        token: "USDT",
        address: "0x55d398326f99059fF775485246999027B3197955"
    },

    {
        token: "CAKE",
        address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
    },

    {
        token: "DOGE",
        address: "0xba2ae424d960c26247dd6c32edc70b295c744c43",
    },




]


export const irenaLpToken = "0x9eeb6c5ff183e6001c65a12d70026b900ae76781"