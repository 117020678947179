import React, { useEffect, useState } from 'react'
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance } from "../hooks/UseContract"
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS, plan } from "../hooks/UseStake"
import { ONEDAYINSECONDS } from '../config/env';
import Accordion from 'react-bootstrap/Accordion';
import Header from './Header'
import Dropdown from 'react-bootstrap/Dropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from "react-router-dom";
import eth from '../images/eth.png'
import bnb from '../images/bnb.png'
import busd from '../images/busd.png'
import leaf from '../images/leaf.png'
import StakingTwo from './StakingTwo'
import { getAccount } from '../hooks/useAccount';

const StakingAccordion = (props) => {
    console.log("StakingAccordion.......", props);
    const [pooldays, setpoolDays] = useState('')
    const [tokenInfo, setTokenInfo] = useState(null);
    const [userStakedAmount, setUserStakedAmount] = useState(0);
    const [pending, setPending] = useState(0)
    const [accountInfo, setAccountInfo] = useState("");
    const [rewardTokenInfo, setRewardTokenInfo] = useState({});
    const [hidden, setHidden] = useState(false);
    const [userTokenBalance, setUserTokenBalance] = useState("");
    const [lockedPeriod, setlockedPeriod] = useState("");
    console.log("lockedPeriod_lockedPeriodlockedPeriod_", lockedPeriod);
    const [setloader, showSetloader] = useState(false);
    const [umudiPerBlock, setUmudiPerBlock] = useState("");
    console.log("umudiPerBlock_V_V_V", umudiPerBlock);
    const [poolLockedDays, setPoolLockedDays] = useState();

    const getData = async () => {
        await getTokenInfo();

        await getrewardtokeninfo();
        const poolLockedDays = await plan(props?.pid == 7 ? 4 : props?.pid);
        // console.log("poolLockedDays",poolLockedDays);
        setPoolLockedDays(poolLockedDays)
        setlockedPeriod(poolLockedDays?.lockedPeriod);
        setUmudiPerBlock(poolLockedDays?.UmudiPerBlock);
        var poolday = (lockedPeriod / ONEDAYINSECONDS);
        setpoolDays(poolday.toFixed(2));
    }
    const getrewardtokeninfo = async () => {

        var RewardTokenInfo = await getRewardTokenInfo(props?.datas?.rewardToken)
        setRewardTokenInfo(RewardTokenInfo);

    }
    const getTokenInfo = async () => {
        await getUserTokenBalance()
        // console.log("props?.datas?.lpToken",props?.datas?.lpToken,props)
        var TokenInfo = await UseTokenInfo(props?.datas?.lpToken)
        setTokenInfo(TokenInfo);
        var RewardTokenInfo = await getRewardTokenInfo(props?.datas?.rewardToken);
        var getuser = await getUser(props.pid, getAccount())
        setUserStakedAmount(getuser?.amount);
        // console.log(getuser,'getuser')
        // const datee = new Date(parseInt(getuser?.userLockedPeriod) * 1000);
        // const unstakeDate = datee.toLocaleDateString();
        // if (getuser?.userLockedPeriod > 0)
        //     setuserstatedate(unstakeDate)
        var UserReward = await getUserReward(props.pid, getAccount())
        var pending = (parseInt(UserReward || 0) + parseInt(getuser?.pendingRewards || 0));
        // console.log("pending", pending, "----","lpToken : ", props?.datas?.lpToken);
        var usdtprice = await getUSDTprice(pending, props?.datas?.lpToken, props?.datas?.rewardToken);
        // var usdtprice = await getUSDTprice(1000, props?.datas?.lpToken);
        // console.log("usdtprice", usdtprice);
        setPending((usdtprice / 10 ** RewardTokenInfo?.decimals).toFixed(14) ? (usdtprice / 10 ** RewardTokenInfo?.decimals).toFixed(14) : "0")
        hideloaderCard()
    }

    const showloaderCard = async () => {
        showSetloader(true);
    }

    const hideloaderCard = async () => {
        showSetloader(false);
    }




    const getUserTokenBalance = async () => {

        if (props.datas.lpToken) {
            var userbalance = await getUserBalance(props.datas.lpToken, getAccount())
            setUserTokenBalance(userbalance);
        }
    }
    const dates = Math.floor(parseInt(lockedPeriod * 1000) / (1000 * 60 * 60 * 24))
    useEffect(() => {
        setAccountInfo(getAccount)
        getData();
        showloaderCard();
    }, [accountInfo])
    return (
        <section>
            <div className={setloader ? 'showloadercard minwidth-534 text-center' : 'showloadercard minwidth-534 text-center d-none'} id="loader_div_card">
                <div className='loader_card' id="loader_card">
                    <img src={leaf} className="spin" />
                </div>

            </div>
            <div>
                <Accordion.Item eventKey={props.pid} className='mb-4'>
                    <Accordion.Header onClick={() => { setHidden(false); props.sendDataToParent(1, tokenInfo, userTokenBalance, props.pid == 7 ? 4 : props.pid, hidden, props?.datas, 0, 0, 0, poolLockedDays) }}>
                        <div className='headerbg'>
                            <div className='headerbg_black'>
                                {/* {props.pid == 7 ? <p className='newplan'>New Plan</p> : <p></p>} */}
                                <p>{dates == 2 ? 'For Try' : dates == 90 ? '3 Months' : dates == 180 ? '6 Months' : dates == 365 ? "1 Year" : ""}   </p>

                                <p className='days'>{Math.floor(parseInt(lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}
                                    <span>
                                        D
                                    </span>
                                </p>
                                <p className='mb-0'>{((parseInt(umudiPerBlock) / 10 ** 18) * Number((lockedPeriod * 1000) / (1000 * 60 * 60 * 24))).toFixed(2)}% APY</p>
                                <p className='mb-0 stack_token'>staking token : <span className='value'> {props.pid == 2 || props.pid == 3 ? "PAYU" : "WBNB, DOGE, CAKE, USDT"}</span></p>
                            </div>
                        </div>
                    </Accordion.Header>
                    {/* <Accordion.Body>
                        <p className='d-flex align-items-center justify-content-between font_acc'>
                            <span>Staked {tokenInfo && tokenInfo?.symbol}</span>
                            <span>1000</span>

                        </p>
                        <p className='d-flex align-items-center justify-content-between font_acc'>
                            <span>Stake Fee</span>
                            <span>{parseInt(props.datas?.depositFeeBP) / 100}%</span>
                        </p>
                        <p className='d-flex align-items-center justify-content-between font_acc'>
                            <span>Returns</span>
                            <span>{((umudiPerBlock / 10 ** 9) * Number((lockedPeriod * 1000) / (1000 * 60 * 60 * 24))).toFixed(2)} %</span>
                        </p>
                        <p className='d-flex align-items-center justify-content-between font_acc'>
                            <span>Earn {rewardTokenInfo && rewardTokenInfo?.symbol}</span>
                            <span>0</span>
                        </p>
                    </Accordion.Body> */}
                </Accordion.Item>
            </div>
        </section >

    )
}

export default StakingAccordion
